
export default {
    data(){
        return{
            mouse:{
                x : 0,
                y : 0,
                realx : 0,
                realy : 0,
            }
        }
    },
    computed:{
        windowHalfX(){
            return this.$store.state.scroll.wW / 2;
        },
        windowHalfY(){
            return this.$store.state.scroll.wH / 2;
        }
    },
    mounted(){
        this.mouse = {
            x : this.windowHalfX,
            y : this.windowHalfY,
            realx : this.windowHalfX,
            realy : this.windowHalfY,
        }
        document.addEventListener( 'mousemove', this.onDocumentMouseMove, { passive : true } );
        document.addEventListener( 'touchstart', this.onDocumentTouchStart, { passive : true } );
        document.addEventListener( 'touchmove', this.onDocumentTouchMove, { passive : true } );
        this.updateMouse();
    },
    methods : {
        onDocumentTouchStart(e){
            if ( e.touches.length === 1 ) {
                this.mouse.x = e.touches[ 0 ].pageX - this.windowHalfX;
                this.mouse.y = e.touches[ 0 ].pageY - this.windowHalfY;
                this.mouse.realx = e.touches[ 0 ].pageX;
                this.mouse.realy = e.touches[ 0 ].pageY;
                this.updateMouse();
            }
        },
        onDocumentTouchMove(e){
            if ( e.touches.length === 1 ) {
                this.mouse.x = e.touches[ 0 ].pageX - this.windowHalfX;
                this.mouse.y = e.touches[ 0 ].pageY - this.windowHalfY;
                this.mouse.realx = e.touches[ 0 ].pageX;
                this.mouse.realy = e.touches[ 0 ].pageY;
                this.updateMouse();
            }
        },
        onDocumentMouseMove(e){
            this.mouse.x = parseInt(e.clientX - this.windowHalfX);
            this.mouse.y = parseInt(e.clientY - this.windowHalfY);
            this.mouse.realx = parseInt(e.clientX);
            this.mouse.realy = parseInt(e.clientY);
            this.updateMouse();
        },
        updateMouse(){
            this.$store.commit('mouse/set',this.mouse);
        }
    },
    beforeDestroy(){
        document.removeEventListener( 'mousemove', this.onDocumentMouseMove, false );
        document.removeEventListener( 'touchstart', this.onDocumentTouchStart, false );
        document.removeEventListener( 'touchmove', this.onDocumentTouchMove, false );
    },
}
